var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"subtitle":null,"title":null,"back-button-text":"Previous","color":"#7367F0","finish-button-text":"Submit","shape":"square"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"before-change":_vm.validationForm,"title":"Basic Information"}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Basic Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Purpose","label-for":"purpose"}},[_c('validation-provider',{attrs:{"name":"Purpose","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"purpose","state":errors.length > 0 ? false:null,"placeholder":"Meeting Room, Internet Credits, AWS Credits etc.","type":"text"},model:{value:(_vm.purpose),callback:function ($$v) {_vm.purpose=$$v},expression:"purpose"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Write max 50 word description","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"before-change":_vm.validationFormInfo,"title":"Stages"}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Stages ")])]),_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.stages),function(stage,index){return _c('b-row',{key:index,staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Stage " + (index+1)),"label-for":("stageName-" + index)}},[_c('validation-provider',{attrs:{"name":"Stage Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("stageName-" + index),"placeholder":"Stage Name","type":"text"},model:{value:(stage.title),callback:function ($$v) {_vm.$set(stage, "title", $$v)},expression:"stage.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"danger","disabled":_vm.stages.length < 2},on:{"click":function($event){return _vm.stages.splice(index, 1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":("stageDescription-" + index)}},[_c('validation-provider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":("stageDescription-" + index),"placeholder":"Write max 50 word description","rows":"3"},model:{value:(stage.description),callback:function ($$v) {_vm.$set(stage, "description", $$v)},expression:"stage.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(index === _vm.stages.length - 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 mt-2 py-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.stages.push({
                        title: null, description: null
                      })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Stage")])],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }