<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationForm"
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Purpose"
                    label-for="purpose"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Purpose"
                      rules=""
                    >
                      <b-form-input
                        id="purpose"
                        v-model="purpose"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Meeting Room, Internet Credits, AWS Credits etc."
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Description"
                    label-for="description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Description"
                      rules=""
                    >
                      <b-form-textarea
                        id="description"
                        v-model="description"
                        placeholder="Write max 50 word description"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Resource Group tab -->
          <tab-content
            :before-change="validationFormInfo"
            title="Stages"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Stages
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(stage, index) in stages"
                    :key="index"
                    class="mb-2"
                  >
                    <b-col>
                      <b-form-group
                        :label="`Stage ${index+1}`"
                        :label-for="`stageName-${index}`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Stage Name"
                        >
                          <b-form-input
                            :id="`stageName-${index}`"
                            v-model="stage.title"
                            placeholder="Stage Name"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="mt-2"
                        :disabled="stages.length < 2"
                        @click="stages.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Description"
                        :label-for="`stageDescription-${index}`"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Description"
                          rules=""
                        >
                          <b-form-textarea
                            :id="`stageDescription-${index}`"
                            v-model="stage.description"
                            placeholder="Write max 50 word description"
                            rows="3"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-if="index === stages.length - 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2 mt-2 py-1"
                        @click="stages.push({
                          title: null, description: null
                        })"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Add Stage</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import gql from 'graphql-tag'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      purpose: '',
      description: '',
      stages: [
        { title: null, description: null },
      ],
      required,
      email,
    }
  },
  computed: {
    formattedStages() {
      const stages = []
      this.stages.forEach((e, i) => stages.push({
        title: e.title,
        description: e.description,
        stage_number: (i + 1).toString(),
      }))
      return stages
    },
  },
  methods: {
    formSubmitted() {
      this.$apollo.mutate({
        mutation: gql`mutation (
          $purpose:String!,
          $description:String,
          $stages:[duediligence_stagetable_insert_input!]!
        ) {
          insert_duediligence_basicinfo(objects:[{
            purpose:$purpose,
            description:$description,
            duediligence_stagetables:{
              data: $stages
            }
          }])
          {
            returning {
              id,
              purpose,
              description
            }
          }
        }`,
        variables: {
          purpose: this.purpose,
          description: this.description,
          stages: this.formattedStages,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.resetValues()
        },
      })
    },
    resetValues() {
      this.purpose = ''
      this.description = ''
      this.stages = [{ title: null, description: null }]
    },
  },
  apollo: {
    client: {
      query() {
        return gql`
        {
          duediligence_basicinfo(where: {id: {_eq: ${this.$route.params.id}}}) {
            purpose,
            id,
            description,
            duediligence_stagetables {
              title
              description
              id
            }
          }
        }`
      },
      result(data) {
        this.purpose = data.data.duediligence_basicinfo[0].purpose
        this.description = data.data.duediligence_basicinfo[0].description
        this.stages = data.data.duediligence_basicinfo[0].duediligence_stagetables
      },
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
